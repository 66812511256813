module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.22.0__react-dom@18.3.1_efng6kf2rxcoutal7j4qthrbz4/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.22.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__okc63luukyzwe7uxogl4ybda54/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
